<template>
  <div class="layout our">

    <!-- 패널 -->
    <component :is="Panel" :mode="`our`" :key="user.login" />

    <!-- 헤더 -->
    <component :is="Header" :mode="`our`" />

    <!-- 컨텐츠 -->
    <div :class="`contents ${resolution.width > resolution.notebook ? `` : `mobile`}`">
      <component :is="ErrorUI" v-if="error" :state="error" :key="`${error}-error`" />
      <div class="contents-inside">
        <slot />
      </div>
    </div>

    <!-- 푸터 -->
    <component :is="Footer" :mode="`our`" />

  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'
// import Error from '~/component/state/error.vue'
// import Panel from '~/component/element/panel.vue'
// import Header from '~/component/layout/header.vue'
// import Footer from '~/component/layout/footer.vue'

export default {
  async setup () {

    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())
    const { error, resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      ErrorUI.value = null
      Panel.value = null
      Header.value = null
      Footer.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const ErrorUI: any = shallowRef(null)
    const Panel: any = shallowRef(null)
    const Header: any = shallowRef(null)
    const Footer: any = shallowRef(null)
    const loadComponent = async () => {
      const ErrorUI_load = await import('~/component/state/error.vue')
      const Panel_load = await import('~/component/element/panel.vue')
      const Header_load = await import('~/component/layout/header.vue')
      const Footer_load = await import('~/component/layout/footer.vue')
      ErrorUI.value = ErrorUI_load.default || ErrorUI_load
      Panel.value = Panel_load.default || Panel_load
      Header.value = Header_load.default || Header_load
      Footer.value = Footer_load.default || Footer_load
    }
    await loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,
      error,
      resolution,

      // 컴포넌트
      ErrorUI,
      Panel,
      Header,
      Footer
    }
  }
}
</script>